<template>
    <section class="zonaAccordion" v-if="risorsa.translations && risorsa.translations.length > 0">
        <div class="cont">
            <div class="innerCont">
                <div class="lista">
                    <AccordionContainer :title="$t('accordion_informazioni_titolo')" :open="false" v-if="(risorsa.impianti && risorsa.impianti.length > 0 && risorsa.impianti.filter((i: any) => i.impianto && ['piscina', 'golf'].includes(i.impianto.tipologia)).length > 0) || (risorsa.piscina_scoperta === true) || risorsa.totale_piste || risorsa.totale_km_piste || (risorsa.piste && risorsa.piste.length > 0)">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-if="risorsa.impianti && risorsa.impianti.length > 0 && risorsa.impianti.filter((i: any) => i.impianto && ['piscina', 'golf'].includes(i.impianto.tipologia)).length > 0">
                                    <div class="titlebody">{{ $t('tipologia') }}</div>
                                    <div class="paragraph">
                                        <ul>
                                            <li v-for="impianto in risorsa.impianti.filter((i: any) => i.impianto && ['piscina', 'golf'].includes(i.impianto.tipologia))">{{ impianto.impianto.translations[0]?.etichetta }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.piscina_scoperta === true || risorsa.golf_numero_buche || risorsa.golf_par || risorsa.golf_lunghezza_campo">
                                    <div class="titlebody">{{ $t('altre_informazioni') }}</div>
                                    <div class="paragraph" v-if="risorsa.piscina_scoperta === true">{{ $t('piscina_scoperta') }}</div>
                                    <div class="paragraph" v-if="risorsa.golf_numero_buche || risorsa.golf_par || risorsa.golf_lunghezza_campo">
                                        <div class="groupRow" v-if="risorsa.golf_numero_buche">
                                            <div>{{ $t('golf_numero_buche') }}</div>
                                            <div class="num">{{ risorsa.golf_numero_buche }}</div>
                                        </div>
                                        <div class="groupRow" v-if="risorsa.golf_par">
                                            <div>{{ $t('golf_par') }}</div>
                                            <div class="num">{{ risorsa.golf_par }}</div>
                                        </div>
                                        <div class="groupRow" v-if="risorsa.golf_lunghezza_campo">
                                            <div>{{ $t('golf_lunghezza_campo') }}</div>
                                            <div class="num">{{ risorsa.golf_lunghezza_campo }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.totale_piste || risorsa.totale_km_piste">
                                    <div class="paragraph">
                                        <div class="groupKm">
                                            <div>{{ $t('piste_totali') }}</div>
                                            <div class="num">{{ risorsa.totale_piste }}</div>
                                            <div class="km" v-if="risorsa.totale_km_piste">{{ risorsa.totale_km_piste }} km</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-for="pista in risorsa.piste">
                                    <div class="titlebody">{{ $t(pista.tipo || 'sci_alpino') }}</div>
                                    <div class="paragraph">
                                        <div class="groupKm" v-if="pista.piste_blu || pista.km_piste_blu">
                                            <div>{{ $t('piste_blu') }}</div>
                                            <div class="num">{{ pista.piste_blu }}</div>
                                            <div class="km" v-if="pista.km_piste_blu">{{ pista.km_piste_blu }} km</div>
                                        </div>
                                        <div class="groupKm" v-if="pista.piste_verdi || pista.km_piste_verdi">
                                            <div>{{ $t('piste_verdi') }}</div>
                                            <div class="num">{{ pista.piste_verdi }}</div>
                                            <div class="km" v-if="pista.km_piste_verdi">{{ pista.km_piste_verdi }} km</div>
                                        </div>
                                        <div class="groupKm" v-if="pista.piste_gialle || pista.km_piste_gialle">
                                            <div>{{ $t('piste_gialle') }}</div>
                                            <div class="num">{{ pista.piste_gialle }}</div>
                                            <div class="km" v-if="pista.km_piste_gialle">{{ pista.km_piste_gialle }} km</div>
                                        </div>
                                        <div class="groupKm" v-if="pista.piste_rosse || pista.km_piste_rosse">
                                            <div>{{ $t('piste_rosse') }}</div>
                                            <div class="num">{{ pista.piste_rosse }}</div>
                                            <div class="km" v-if="pista.km_piste_rosse">{{ pista.km_piste_rosse }} km</div>
                                        </div>
                                        <div class="groupKm" v-if="pista.piste_nere || pista.km_piste_nere">
                                            <div>{{ $t('piste_nere') }}</div>
                                            <div class="num">{{ pista.piste_nere }}</div>
                                            <div class="km" v-if="pista.km_piste_nere">{{ pista.km_piste_nere }} km</div>
                                        </div>
                                        <div class="groupKm" v-if="pista.totale_piste || pista.totale_km">
                                            <div>{{ $t('piste_totali') }}</div>
                                            <div class="num">{{ pista.totale_piste }}</div>
                                            <div class="km" v-if="pista.totale_km">{{ pista.totale_km }} km</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_impianti_titolo')" :open="false" v-if="['comprensorio', 'stazione_sci'].includes(risorsa.tipo) && ((risorsa.impianti && risorsa.impianti.length > 0 && risorsa.impianti.filter((i: any) => i.impianto && ['sci_inverno', 'sci_estate'].includes(i.impianto.tipologia)).length > 0) || risorsa.totale_impianti_invernali || risorsa.totale_impianti_estivi)">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-if="risorsa.impianti.filter((i: any) => i.impianto && ['sci_inverno'].includes(i.impianto.tipologia)).length > 0 || risorsa.totale_impianti_invernali">
                                    <div class="titlebody">{{ $t('impianti_invernali') }}</div>
                                    <div class="paragraph">
                                        <div class="groupRow" v-for="impianto in risorsa.impianti.filter((i: any) => i.impianto && ['sci_inverno'].includes(i.impianto.tipologia))">
                                            <div>{{ impianto.impianto.translations[0]?.etichetta }}</div>
                                            <div class="num" v-if="impianto.numero">{{ impianto.numero }}</div>
                                        </div>
                                        <div class="groupRow" v-if="risorsa.totale_impianti_invernali">
                                            <div>{{ $t('impianti_totali') }}</div>
                                            <div class="num">{{ risorsa.totale_impianti_invernali }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.impianti.filter((i: any) => i.impianto && ['sci_estate'].includes(i.impianto.tipologia)).length > 0 || risorsa.totale_impianti_estivi">
                                    <div class="titlebody">{{ $t('impianti_estivi') }}</div>
                                    <div class="paragraph">
                                        <div class="groupRow" v-for="impianto in risorsa.impianti.filter((i: any) => i.impianto && ['sci_estate'].includes(i.impianto.tipologia))">
                                            <div>{{ impianto.impianto.translations[0]?.etichetta }}</div>
                                            <div class="num" v-if="impianto.numero">{{ impianto.numero }}</div>
                                        </div>
                                        <div class="groupRow" v-if="risorsa.totale_impianti_estivi">
                                            <div>{{ $t('impianti_totali') }}</div>
                                            <div class="num">{{ risorsa.totale_impianti_estivi }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_attrezzature_titolo')" :open="false" v-if="risorsa.servizi_attrezzature && risorsa.servizi_attrezzature.length > 0">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-if="risorsa.servizi_attrezzature && risorsa.servizi_attrezzature.length > 0">
                                    <div class="servizi">
                                        <div class="paragraph">
                                            <ul>
                                                <li v-for="servizio in risorsa.servizi_attrezzature">{{ servizio.servizi_attrezzature_id.translations[0]?.etichetta }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_cucina_titolo')" :open="false" v-if="risorsa.ristorazione_tipo_cucina && risorsa.ristorazione_tipo_cucina.length > 0">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group">
                                    <template v-for="tipoCucina in risorsa.ristorazione_tipo_cucina">
                                        <div class="titlebody">{{ tipoCucina.risorse_tipo_cucina_id.translations[0]?.etichetta }}</div>
                                        <div class="paragraph" v-if="tipoCucina.risorse_tipo_cucina_id.tipologia_collegata == 'ricette' && risorsa.ristorazione_ricette.length > 0">
                                            <ul>
                                                <li v-for="ricetta in risorsa.ristorazione_ricette">{{ ricetta.risorse_ricette_id.nome }}</li>
                                            </ul>
                                        </div>
                                        <div class="paragraph" v-if="tipoCucina.risorse_tipo_cucina_id.tipologia_collegata == 'regioni' && risorsa.ristorazione_cucina_regione.length > 0">
                                            <ul>
                                                <template v-for="regione in risorsa.ristorazione_cucina_regione">
                                                    <li v-if="regione && regione.regioni_id && regione.regioni_id.nome">{{ regione.regioni_id.nome }}</li>
                                                </template>
                                            </ul>
                                        </div>
                                        <div class="paragraph" v-if="tipoCucina.risorse_tipo_cucina_id.tipologia_collegata == 'stati' && risorsa.ristorazione_cucina_nazione.length > 0">
                                            <ul>
                                                <template v-for="nazione in risorsa.ristorazione_cucina_nazione">
                                                    <li v-if="nazione && nazione.stati_id && nazione.stati_id.nome">{{ nazione.stati_id.nome }}</li>
                                                </template>
                                            </ul>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_servizi_titolo')" :open="false" v-if="risorsa.tipo != 'ricettivita' && ((risorsa.ristorazione_coperti_interni || risorsa.ristorazione_coperti_esterni || (risorsa.ristorazione_posti && risorsa.ristorazione_posti.length > 0)) || (risorsa.servizi_attivita && risorsa.servizi_attivita.length > 0) || (risorsa.impianti && risorsa.impianti.length > 0 && risorsa.impianti.filter((i: any) => i.impianto && !['piscina', 'golf'].includes(i.impianto.tipologia)).length > 0) || (risorsa.lingue_straniere_parlate && risorsa.lingue_straniere_parlate.length > 0))">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-if="risorsa.ristorazione_coperti_interni || risorsa.ristorazione_coperti_esterni || (risorsa.ristorazione_posti && risorsa.ristorazione_posti.length > 0)">
                                    <h3 class="titlebody">{{ $t('coperti') }}</h3>
                                    <div class="paragraph">
                                        <div class="groupRow" v-if="risorsa.ristorazione_coperti_interni">
                                            <div>{{ $t('coperti_interni') }}</div>
                                            <div class="num">{{ risorsa.ristorazione_coperti_interni }}</div>
                                        </div>
                                        <div class="groupRow" v-if="risorsa.ristorazione_coperti_esterni">
                                            <div>{{ $t('coperti_esterni') }}</div>
                                            <div class="num">{{ risorsa.ristorazione_coperti_esterni }}</div>
                                        </div>
                                        <div class="groupRow" v-for="posti in risorsa.ristorazione_posti">
                                            <div>{{ $t(posti.tipologia) }}</div>
                                            <div class="num">{{ posti.quantita }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.servizi_attivita && risorsa.servizi_attivita.length > 0">
                                    <div class="servizi">
                                        <div class="servizio" v-for="attivita in risorsa.servizi_attivita">
                                            <div class="titlebody">{{ attivita.servizi_attivita_id?.translations[0]?.etichetta }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-if="(risorsa.impianti && risorsa.impianti.length > 0 && risorsa.impianti.filter((i: any) => i.impianto && !['piscina', 'golf', 'sci_inverno', 'sci_estate'].includes(i.impianto.tipologia)).length > 0) || (risorsa.vende && risorsa.vende.length > 0)">
                                    <div class="servizi">
                                        <div class="servizio" v-for="impianto in risorsa.impianti.filter((i: any) => i.impianto && !['piscina', 'golf', 'sci_inverno', 'sci_estate'].includes(i.impianto.tipologia))">
                                            <div class="titlebody">
                                                <div>{{ impianto.impianto.translations[0]?.etichetta }}</div>
                                                <div class="num" v-if="impianto.numero">{{ impianto.numero }}</div>
                                            </div>
                                            <div class="paragraph" v-if="impianto.translations[0] && impianto.translations[0].note" v-html="impianto.translations[0].note"></div>
                                        </div>
                                        <div class="servizio" v-if="risorsa.vende && risorsa.vende.length > 0">
                                            <div class="titlebody">{{ $t('punto_vendita') }}</div>
                                            <div class="paragraph">{{ $t('in_vendita_biglietteria') }}:
                                                <template v-for="(oggetto, index) in risorsa.vende">
                                                    <NuxtLinkLocale :to="getCardLink({ item: oggetto.prodotti_id }, 'prodotti').address">{{ oggetto.prodotti_id.translations[0]?.titolo }}</NuxtLinkLocale>
                                                    <template v-if="index != risorsa.vende.length - 1">, </template>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-if="(risorsa.servizi_ristorazione && risorsa.servizi_ristorazione.length > 0)">
                                    <h3 class="titlebody">{{ $t('servizi') }}</h3>
                                    <!--<div class="paragraph" v-if="risorsa.carte_credito && risorsa.carte_credito.length > 0">{{ $t('pagamenti_accettati') }}: {{ risorsa.carte_credito.map((c: any) => c.carte_credito_id.translations[0]?.etichetta).join(', ') }}</div>-->
                                    <div class="paragraph" v-if="risorsa.servizi_ristorazione && risorsa.servizi_ristorazione.length > 0">
                                        <ul>
                                            <li v-for="servizio in risorsa.servizi_ristorazione">{{ servizio.servizi_ristorazione_id.translations[0]?.etichetta }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.lingue_straniere_parlate && risorsa.lingue_straniere_parlate.length > 0">
                                    <h3 class="titlebody">{{ $t('lingue_parlate') }}</h3>
                                    <div class="paragraph">
                                        <div>{{risorsa.lingue_straniere_parlate.filter((o: any) => o && o.lingue_id && o.lingue_id.translations && o.lingue_id.translations[0]).map((o: any) => o.lingue_id.translations[0]?.etichetta).join(', ')}}</div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_tariffe_titolo')" :open="false" v-if="(risorsa.tariffe && risorsa.tariffe.length > 0 && risorsa.tipo != 'ricettivita') || (risorsa.ristorazione_tariffe && risorsa.ristorazione_tariffe.length > 0) || risorsa.ristorazione_fascia_prezzo || (risorsa.ricettivita_tariffe && risorsa.ricettivita_tariffe.length > 0) || risorsa.ricettivita_periodi || risorsa.ricettivita_apertura">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-if="risorsa.ricettivita_periodi && risorsa.ricettivita_periodi.length > 0">
                                    <h3 class="titlebody">{{ $t('apertura') }}</h3>
                                    <div class="paragraph colonne" v-if="risorsa.ricettivita_periodi.filter((p: any) => p.periodo == 'bassa_stagione').length > 0">
                                        <div class="groupRow" v-for="periodo in risorsa.ricettivita_periodi">
                                            <div>{{ $t(periodo.periodo) }}</div>
                                            <div class="num">{{ dataSenzaAnno(periodo.data_inizio) }}-{{ dataSenzaAnno(periodo.data_fine) }}</div>
                                        </div>
                                    </div>
                                    <div class="paragraph colonne" v-else>
                                        <div class="groupRow" v-for="periodo in risorsa.ricettivita_periodi">
                                            <div>{{ $t('stagione_unica') }}</div>
                                            <div class="num">{{ dataSenzaAnno(periodo.data_inizio) }}-{{ dataSenzaAnno(periodo.data_fine) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['singola_alta_stagione', 'singola_bassa_stagione', 'singola_senza_bagno_alta_stagione', 'singola_senza_bagno_bassa_stagione', 'doppia_alta_stagione', 'doppia_bassa_stagione', 'doppia_senza_bagno_alta_stagione', 'doppia_senza_bagno_bassa_stagione', 'tripla_alta_stagione', 'tripla_bassa_stagione', 'tripla_senza_bagno_alta_stagione', 'tripla_senza_bagno_bassa_stagione', 'quattro_letti_alta_stagione', 'quattro_letti_bassa_stagione', 'quattro_letti_senza_bagno_alta_stagione', 'quattro_letti_senza_bagno_bassa_stagione', 'suite_alta_stagione', 'suite_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('camere') }}</h3>
                                    <div class="paragraph colonne">
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['singola_alta_stagione', 'singola_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('singola') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'singola'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['singola_senza_bagno_alta_stagione', 'singola_senza_bagno_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('singola_senza_bagno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'singola_senza_bagno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['uso_singola_alta_stagione', 'uso_singola_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('uso_singola') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'uso_singola'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['uso_singola_senza_bagno_alta_stagione', 'uso_singola_senza_bagno_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('uso_singola_senza_bagno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'uso_singola_senza_bagno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['doppia_alta_stagione', 'doppia_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('doppia') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'doppia'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['doppia_senza_bagno_alta_stagione', 'doppia_senza_bagno_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('doppia_senza_bagno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'doppia_senza_bagno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['tripla_alta_stagione', 'tripla_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('tripla') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'tripla'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['tripla_senza_bagno_alta_stagione', 'tripla_senza_bagno_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('tripla_senza_bagno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'tripla_senza_bagno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['quattro_letti_alta_stagione', 'quattro_letti_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('quattro_letti') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'quattro_letti'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['quattro_letti_senza_bagno_alta_stagione', 'quattro_letti_senza_bagno_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('quattro_letti_senza_bagno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'quattro_letti_senza_bagno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['suite_alta_stagione', 'suite_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('suite') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'suite'" />
                                        </template>
                                    </div>
                                </div>
                                <OggettoTariffePeriodi :risorsa="risorsa" tipologia="monolocale" />
                                <OggettoTariffePeriodi :risorsa="risorsa" tipologia="bilocale" />
                                <OggettoTariffePeriodi :risorsa="risorsa" tipologia="plurilocale" />
                                <OggettoTariffePeriodi :risorsa="risorsa" tipologia="casa" />
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['campeggio_adulti_alta_stagione', 'campeggio_adulti_bassa_stagione', 'campeggio_bambini_alta_stagione', 'campeggio_bambini_bassa_stagione', 'campeggio_auto_alta_stagione', 'campeggio_auto_bassa_stagione', 'campeggio_moto_alta_stagione', 'campeggio_moto_bassa_stagione', 'campeggio_cani_alta_stagione', 'campeggio_cani_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('campeggio') }}</h3>
                                    <div class="paragraph colonne">
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['campeggio_adulti_alta_stagione', 'campeggio_adulti_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('adulti') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'campeggio_adulti'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['campeggio_bambini_alta_stagione', 'campeggio_bambini_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bambini') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'campeggio_bambini'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['campeggio_auto_alta_stagione', 'campeggio_auto_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('auto') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'campeggio_auto'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['campeggio_moto_alta_stagione', 'campeggio_moto_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('moto') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'campeggio_moto'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['campeggio_cani_alta_stagione', 'campeggio_cani_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('cani') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'campeggio_cani'" />
                                        </template>
                                    </div>
                                </div>
                                <OggettoTariffePeriodi :risorsa="risorsa" tipologia="mobilhome" />
                                <OggettoTariffePeriodi :risorsa="risorsa" tipologia="chalet" />
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_alta_stagione', 'bungalow_bassa_stagione', 'bungalow_bagno_1giorno_bassa_stagione', 'bungalow_bagno_1giorno_alta_stagione', 'bungalow_senza_bagno_1giorno_bassa_stagione', 'bungalow_senza_bagno_1giorno_alta_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('bungalow') }}</h3>
                                    <div class="paragraph colonne">
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_alta_stagione', 'bungalow_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_bagno_1giorno_bassa_stagione', 'bungalow_bagno_1giorno_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('1giorno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_bagno_1giorno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_senza_bagno_1giorno_bassa_stagione', 'bungalow_senza_bagno_1giorno_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('1giorno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_senza_bagno_1giorno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_bagno_1settimana_bassa_stagione', 'bungalow_bagno_1settimana_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('1settimana') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_bagno_1settimana'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_senza_bagno_1settimana_bassa_stagione', 'bungalow_senza_bagno_1settimana_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('1settimana') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_senza_bagno_1settimana'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_bagno_2settimane_bassa_stagione', 'bungalow_bagno_2settimane_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('2settimane') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_bagno_2settimane'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_senza_bagno_2settimane_bassa_stagione', 'bungalow_senza_bagno_2settimane_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('2settimane') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_senza_bagno_2settimane'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_bagno_1mese_bassa_stagione', 'bungalow_bagno_1mese_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('1mese') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_bagno_1mese'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['bungalow_senza_bagno_1mese_bassa_stagione', 'bungalow_senza_bagno_1mese_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('1mese') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'bungalow_senza_bagno_1mese'" />
                                        </template>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['roulotte_alta_stagione', 'roulotte_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('roulotte') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'roulotte'" />
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['camper_alta_stagione', 'camper_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('camper') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'camper'" />
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_alta_stagione', 'caravan_bassa_stagione', 'caravan_bagno_1giorno_bassa_stagione', 'caravan_bagno_1giorno_alta_stagione', 'caravan_senza_bagno_1giorno_bassa_stagione', 'caravan_senza_bagno_1giorno_alta_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('caravan') }}</h3>
                                    <div class="paragraph colonne">
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_alta_stagione', 'caravan_bassa_stagione'].includes(t.tipologia)).length > 0">
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_bagno_1giorno_bassa_stagione', 'caravan_bagno_1giorno_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('1giorno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_bagno_1giorno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_senza_bagno_1giorno_bassa_stagione', 'caravan_senza_bagno_1giorno_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('1giorno') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_senza_bagno_1giorno'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_bagno_1settimana_bassa_stagione', 'caravan_bagno_1settimana_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('1settimana') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_bagno_1settimana'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_senza_bagno_1settimana_bassa_stagione', 'caravan_senza_bagno_1settimana_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('1settimana') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_senza_bagno_1settimana'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_bagno_2settimane_bassa_stagione', 'caravan_bagno_2settimane_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('2settimane') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_bagno_2settimane'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_senza_bagno_2settimane_bassa_stagione', 'caravan_senza_bagno_2settimane_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('2settimane') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_senza_bagno_2settimane'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_bagno_1mese_bassa_stagione', 'caravan_bagno_1mese_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('bagno') }} - {{ $t('1mese') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_bagno_1mese'" />
                                        </template>
                                        <template v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['caravan_senza_bagno_1mese_bassa_stagione', 'caravan_senza_bagno_1mese_alta_stagione'].includes(t.tipologia)).length > 0">
                                            <div class="title4">{{ $t('senza_bagno') }} - {{ $t('1mese') }}</div>
                                            <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'caravan_senza_bagno_1mese'" />
                                        </template>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['tenda_alta_stagione', 'tenda_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('tenda') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'tenda'" />
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['piazzola_alta_stagione', 'piazzola_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('piazzola') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'piazzola'" />
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['camerate_alta_stagione', 'camerate_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('camerate') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'camerate'" />
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['mezza_pensione_alta_stagione', 'mezza_pensione_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('mezza_pensione') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'mezza_pensione'" />
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['pensione_completa_alta_stagione', 'pensione_completa_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('pensione_completa') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'pensione_completa'" />
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_tariffe.filter((t: any) => ['letto_in_aggiunta_alta_stagione', 'letto_in_aggiunta_bassa_stagione'].includes(t.tipologia)).length > 0">
                                    <h3 class="titlebody">{{ $t('letto_in_aggiunta') }}</h3>
                                    <div class="paragraph colonne">
                                        <OggettoTariffaStagione :risorsa="risorsa" :tipologia="'letto_in_aggiunta'" />
                                    </div>
                                </div>
                            </div>
                            <div class="groupTariffe" v-if="risorsa.tariffe && risorsa.tariffe.length > 0 && risorsa.tipo != 'ricettivita'">
                                <div class="tariffe" v-if="tariffeSpeciali.length > 0">
                                    <OggettoTariffa v-for="tariffa in tariffeSpeciali" :tariffa="tariffa" :total="tariffeSpeciali.length" />
                                </div>
                                <div class="tariffe" v-if="tariffeStandard.length > 0">
                                    <OggettoTariffa v-for="tariffa in tariffeStandard" :tariffa="tariffa" :total="tariffeStandard.length" />
                                </div>
                            </div>
                            <div class="groupTesti" v-if="(risorsa.ristorazione_tariffe && risorsa.ristorazione_tariffe.length > 0) || risorsa.ristorazione_fascia_prezzo">
                                <div class="group">
                                    <h3 class="titlebody">{{ $t('prezzi') }}</h3>
                                    <div class="paragraph">
                                        <div class="groupRow" v-if="risorsa.ristorazione_fascia_prezzo">
                                            <div>{{ $t('fascia_di_prezzo') }}</div>
                                            <div class="num">{{ risorsa.ristorazione_fascia_prezzo.translations[0]?.etichetta }}</div>
                                        </div>
                                        <div class="groupRow" v-for="tariffa in risorsa.ristorazione_tariffe">
                                            <div>{{ $t(tariffa.tipologia) }}</div>
                                            <OggettoTariffaRange :tariffa="tariffa" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="groupTesti" v-if="risorsa.durata_visite && risorsa.durata_visite.length > 0">
                                <div class="group">
                                    <h3 class="titlebody">{{ $t('durata_visita') }}</h3>
                                    <div class="paragraph">
                                        <div class="groupRow" v-for="visita in risorsa.durata_visite">
                                            <div>{{ $t(visita.tipologia) }}</div>
                                            <div class="num">{{ $t(visita.durata) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_capacita_ricettiva_titolo')" :open="false" v-if="risorsa.ricettivita_numero_camere || risorsa.ricettivita_numero_letti || (risorsa.ricettivita_camere && risorsa.ricettivita_camere.filter((c: any) => c.tipologia == 'camere_disabili').length > 0) || risorsa.ricettivita_numero_coperti">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group">
                                    <div class="paragraph">
                                        <div class="groupRow" v-if="risorsa.ricettivita_numero_camere">
                                            <div>{{ $t('camere') }}</div>
                                            <div class="num">{{ risorsa.ricettivita_numero_camere }}</div>
                                        </div>
                                        <div class="groupRow" v-if="risorsa.ricettivita_numero_letti">
                                            <div>{{ $t('posti_letto') }}</div>
                                            <div class="num">{{ risorsa.ricettivita_numero_letti }}</div>
                                        </div>
                                        <div class="groupRow" v-if="risorsa.ricettivita_numero_bagni && risorsa.ricettivita_tipologia == 'rifugio_alpino'">
                                            <div>{{ $t('bagni') }}</div>
                                            <div class="num">{{ risorsa.ricettivita_numero_bagni }}</div>
                                        </div>
                                        <template v-if="camerePerTipologiaRicettivita.length > 0">
                                            <div class="groupRow" v-for="camera in camerePerTipologiaRicettivita">
                                                <div>{{ $t(camera.tipologia) }}</div>
                                                <div class="num">{{ camera.numero }}</div>
                                            </div>
                                        </template>
                                        <div class="groupRow" v-if="risorsa.ricettivita_numero_coperti">
                                            <div>{{ $t('coperti') }}</div>
                                            <div class="num">{{ risorsa.ricettivita_numero_coperti }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_dotazioni_titolo')" :open="false" v-if="dotazioniPerAmbiente.length > 0">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-for="ambiente in dotazioniPerAmbiente">
                                    <h3 class="titlebody">{{ $t(`dotazioni_${ambiente[0]}`) }}</h3>
                                    <div class="paragraph">{{ambiente[1].map((d: any) => d.dotazione?.translations[0]?.etichetta).join(', ')}}</div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_servizi_attivita_titolo')" :open="false" v-if="risorsa.tipo == 'ricettivita' && ((risorsa.servizi_ristorazione && risorsa.servizi_ristorazione.length > 0) || (risorsa.ricettivita_colazione && risorsa.ricettivita_colazione.length > 0) || (risorsa.vende && risorsa.vende.length > 0) || (risorsa.servizi_attivita && risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'generica' || s.servizi_attivita_id.tipologia == 'attivita').length > 0) || (risorsa.ricettivita_ospitalita && risorsa.ricettivita_ospitalita.length > 0) || (risorsa.ricettivita_animali_domestici && risorsa.ricettivita_animali_domestici.length > 0) || risorsa.bike_friendly || (risorsa.servizi_attivita && risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'sport' || s.servizi_attivita_id.tipologia == 'salute_benessere').length > 0) || (risorsa.servizi_ricettivita && risorsa.servizi_ricettivita.length > 0))">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-if="risorsa.servizi_ricettivita && risorsa.servizi_ricettivita.length > 0">
                                    <h3 class="titlebody">{{ $t('servizi_generali') }}</h3>
                                    <div class="paragraph">{{risorsa.servizi_ricettivita.filter((s: any) => s.servizi_ricettivita_id).map((s: any) => s.servizi_ricettivita_id?.translations[0]?.etichetta).join(', ')}}</div>
                                </div>
                                <div class="group" v-if="risorsa.bike_friendly || (risorsa.servizi_attivita && risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'sport' || s.servizi_attivita_id.tipologia == 'salute_benessere').length > 0)">
                                    <h3 class="titlebody">{{ $t('sport_benessere') }}</h3>
                                    <div class="paragraph">
                                        <template v-if="risorsa.servizi_attivita && risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'sport').length > 0">
                                            <div class="title4">{{ $t('sport') }}</div>
                                            <div>{{risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'sport').map((o: any) => o.servizi_attivita_id.translations[0]?.etichetta).join(', ')}}</div>
                                        </template>
                                        <template v-if="risorsa.servizi_attivita && risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'salute_benessere').length > 0">
                                            <div class="title4">{{ $t('benessere') }}</div>
                                            <div>{{risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'salute_benessere').map((o: any) => o.servizi_attivita_id.translations[0]?.etichetta).join(', ')}}</div>
                                        </template>
                                        <div class="title4" v-if="risorsa.bike_friendly">{{ $t('bike_friendly') }}</div>
                                    </div>
                                    <!--<div class="paragraph">{{ risorsa.impianti }}</div>-->
                                </div>
                                <div class="group" v-if="(risorsa.ricettivita_ospitalita && risorsa.ricettivita_ospitalita.length > 0) || (risorsa.ricettivita_animali_domestici && risorsa.ricettivita_animali_domestici.length > 0)">
                                    <h3 class="titlebody">{{ $t('ospitalita') }}</h3>
                                    <div class="paragraph">
                                        <div v-if="risorsa.ricettivita_ospitalita && risorsa.ricettivita_ospitalita.length > 0">{{risorsa.ricettivita_ospitalita.map((o: any) => $t(o)).join(', ')}}</div>
                                        <template v-if="risorsa.ricettivita_animali_domestici && risorsa.ricettivita_animali_domestici.length > 0">
                                            <div class="title4">{{ $t('animali') }}</div>
                                            <div>{{risorsa.ricettivita_animali_domestici.map((o: any) => $t(o)).join(', ')}}</div>
                                        </template>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.servizi_attivita && risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'generica' || s.servizi_attivita_id.tipologia == 'attivita').length > 0">
                                    <h3 class="titlebody">{{ $t('attivita') }}</h3>
                                    <div class="paragraph">{{risorsa.servizi_attivita.filter((s: any) => s.servizi_attivita_id.tipologia == 'generica' || s.servizi_attivita_id.tipologia == 'attivita').map((o: any) => o.servizi_attivita_id.translations[0]?.etichetta).join(', ')}}</div>
                                </div>
                                <div class="group" v-if="(risorsa.servizi_ristorazione && risorsa.servizi_ristorazione.length > 0) || (risorsa.ricettivita_colazione && risorsa.ricettivita_colazione.length > 0)">
                                    <h3 class="titlebody">{{ $t('ristorazione') }}</h3>
                                    <div class="paragraph">
                                        <div v-if="risorsa.servizi_ristorazione && risorsa.servizi_ristorazione.length > 0">{{risorsa.servizi_ristorazione.map((o: any) => o.servizi_ristorazione_id.translations[0]?.etichetta).join(', ')}}</div>
                                        <template v-if="risorsa.ricettivita_colazione && risorsa.ricettivita_colazione.length > 0">
                                            <div class="title4">{{ $t('colazione') }}</div>
                                            <div>{{risorsa.ricettivita_colazione.map((o: any) => $t(o)).join(', ')}}</div>
                                        </template>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.vende && risorsa.vende.length > 0">
                                    <h3 class="titlebody">{{ $t('punto_vendita') }}</h3>
                                    <div class="paragraph">
                                        <div>
                                            <template v-for="(oggetto, index) in risorsa.vende">
                                                <NuxtLinkLocale :to="getCardLink({ item: oggetto.prodotti_id }, 'prodotti').address">{{ oggetto.prodotti_id.translations[0]?.titolo }}</NuxtLinkLocale>
                                                <template v-if="index != risorsa.vende.length - 1">, </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_persone_ammesse_titolo')" :open="false" v-if="risorsa.ricettivita_persone_ammesse && risorsa.ricettivita_persone_ammesse.length > 0">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group">
                                    <div class="paragraph">
                                        <ul>
                                            <li v-for="sottotipologia in risorsa.ricettivita_persone_ammesse">{{ sottotipologia.sottotipologia_ricettivita_id.translations[0]?.etichetta }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_orari_titolo')" :open="false" v-if="(risorsa.orari && risorsa.orari.length > 0) || (risorsa.orari_straordinari && risorsa.orari_straordinari.length > 0) || (risorsa.translations_orari && risorsa.translations_orari[0] && risorsa.translations_orari[0].chiusura_biglietteri)">
                        <template #content>
                            <div class="groupTesti">
                                <div class="orari" v-if="risorsa.orari && risorsa.orari.length > 0">
                                    <OggettoOrario v-for="orario in risorsa.orari" :orario="orario" />
                                </div>
                                <div class="orari" v-if="orariStraordinari.length > 0">
                                    <div class="titlebody infraTitolo">{{ $t('aperture_chiusure_straordinarie') }}</div>
                                    <OggettoOrario v-for="orario in orariStraordinari" :orario="orario" :noborder="true" />
                                </div>
                                <div class="group" v-if="risorsa.translations_orari[0] && risorsa.translations_orari[0].chiusura_biglietteria">
                                    <div class="titlebody">{{ $t('chiusura_biglietteria') }}</div>
                                    <div class="paragraph" v-html="risorsa.translations_orari[0].chiusura_biglietteria"></div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_raggiungibilita_titolo')" :open="false" v-if="risorsa.minuti_dal_parcheggio || (risorsa.ricettivita_raggiungibilita && risorsa.ricettivita_raggiungibilita.length > 0)">
                        <template #content>
                            <div class="groupTesti">
                                <div class="group" v-if="risorsa.minuti_dal_parcheggio">
                                    <div class="paragraph">
                                        <div class="groupRow">
                                            <div>{{ $t('minuti_dal_parcheggio') }}</div>
                                            <div class="num">{{ risorsa.minuti_dal_parcheggio }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group" v-if="risorsa.ricettivita_raggiungibilita && risorsa.ricettivita_raggiungibilita.length > 0">
                                    <h3 class="titlebody">{{ $t('informazioni_generali') }}</h3>
                                    <div class="paragraph">{{risorsa.ricettivita_raggiungibilita.map((r: any) => $t(r)).join(', ')}}</div>
                                </div>
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_materiali_scaricabili_titolo')" :open="false" v-if="risorsa.translations[0].allegati && risorsa.translations[0].allegati.length > 0">
                        <template #content>
                            <div class="allegati">
                                <template v-for="allegato in risorsa.translations[0].allegati">
                                    <OggettoFile :etichetta="allegato.directus_files_id.title" :file="allegato.directus_files_id" fillColor="text" />
                                </template>
                            </div>
                        </template>
                    </AccordionContainer>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const { dataSenzaAnno, seEventoPassato } = useOrari()
const { getCardLink } = useCard()
const props = defineProps<{ risorsa: any }>()

const tariffeStandard = computed(() => {
    if (props.risorsa && props.risorsa.tariffe && props.risorsa.tariffe.length > 0) {
        return props.risorsa.tariffe.filter((t: any) => t.tariffa_straordinaria === false)
    } else return []
})
const tariffeSpeciali = computed(() => {
    if (props.risorsa && props.risorsa.tariffe && props.risorsa.tariffe.length > 0) {
        return props.risorsa.tariffe.filter((t: any) => t.tariffa_straordinaria === true && (!t.data_fine || new Date(t.data_fine).getTime() >= new Date().getTime()))
    } else return []
})
const orariStraordinari = computed(() => {
    if (props.risorsa.orari_straordinari && props.risorsa.orari_straordinari.length > 0) {
        return props.risorsa.orari_straordinari.filter((o: any) => !seEventoPassato([o]))
    } else return []
})
const dotazioniPerAmbiente = computed<any[]>(() => {
    if (props.risorsa && props.risorsa.ricettivita_dotazioni && props.risorsa.ricettivita_dotazioni.length > 0) {
        const ambienti = props.risorsa.ricettivita_dotazioni.reduce(function (r: any, a: any) {
            r[a.ambiente] = r[a.ambiente] || [];
            r[a.ambiente].push(a);
            return r;
        }, Object.create(null));
        return Object.entries(ambienti)
    } else return []
})
const camerePerTipologiaRicettivita = computed<any[]>(() => {
    if (props.risorsa && props.risorsa.ricettivita_camere && props.risorsa.ricettivita_camere.length > 0) {
        switch (props.risorsa.ricettivita_tipologia) {
            case 'campeggio':
                const allowedCampeggio = [
                    'camere_chalet',
                    'camere_mobilhome',
                    'camere_bungalow',
                    'camere_caravan',
                    'chalet',
                    'mobilhome',
                    'bungalow_bagno',
                    'bungalow_senza_bagno',
                ]
                return props.risorsa.ricettivita_camere.filter((c: any) => allowedCampeggio.includes(c.tipologia))
            default:
                return props.risorsa.ricettivita_camere.filter((c: any) => c.tipologia == 'camere_disabili')
        }
    } else return []
})
</script>

<style lang="scss" scoped>
.zonaAccordion {
    .lista {
        display: flex;
        flex-direction: column;
        gap: var(--margin-gap);

        .groupOrari,
        .groupTariffe {
            display: flex;
            flex-direction: column;
            gap: var(--margin-group);
        }

        .groupTesti {
            display: flex;
            flex-direction: column;
            gap: var(--margin-double);

            &:deep(.group) {
                .servizi {
                    display: flex;
                    flex-direction: column;
                    gap: var(--margin-gap);

                    .titlebody {
                        text-transform: none;
                    }
                }

                .colonne {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 0 var(--margin-double);

                    .title4 {
                        grid-column: 1 / span 2;
                    }
                }

                .titlebody {
                    text-transform: uppercase;
                    border-bottom: 1px solid var(--color-azure-back);
                    padding-bottom: 6px;
                    margin-bottom: 6px;

                    display: flex;
                    justify-content: space-between;
                }

                .groupRow {
                    display: flex;
                    justify-content: space-between;
                    gap: var(--margin-gap);

                    .num {
                        font-weight: 800;
                    }
                }

                .groupKm {
                    display: grid;
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                    gap: var(--margin-gap);

                    div:first-of-type {
                        grid-column: 1 / span 2;
                    }

                    .num,
                    .km {
                        font-weight: 800;
                        text-align: right
                    }
                }

                .paragraph ul {
                    margin-bottom: var(--margin-default);
                }

                .paragraph+.paragraph {
                    margin-top: var(--margin-default);
                }

                a {
                    color: currentColor;
                    font-weight: 400;
                    text-decoration: underline;

                    text-decoration-thickness: 1px;
                    text-underline-offset: 3px;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }

        .orari,
        .tariffe {
            display: flex;
            flex-direction: column;
            gap: var(--margin-default);

            .infraTitolo {
                text-transform: uppercase;
                border-bottom: 1px solid var(--color-azure-back);
                padding-bottom: 6px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .zonaAccordion {
        .lista {
            .groupTesti {
                &:deep(.group) {
                    .colonne {
                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 0 var(--margin-default);

                        .title4 {
                            grid-column: 1 / span 1;
                        }
                    }

                    .groupRow {
                        display: grid;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        gap: var(--margin-default);

                        .num {
                            text-wrap: balance;
                        }

                        &+.groupRow {
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
    }
}
</style>