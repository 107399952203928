<template>
    <article v-if="risorsa">

        <section class="risorsaInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="risorsa.translations && risorsa.translations[0]?.body" :body="risorsa.translations[0]?.body" />
                        <ModuloBody v-if="risorsa.translations_ristorazione && risorsa.translations_ristorazione[0]?.cuoco" :body="risorsa.translations_ristorazione[0]?.cuoco" :titolo="$t('il_cuoco')" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="risorsa.media && risorsa.media.length == 1" :image="risorsa.media[0]" />
                        <ModuloMedia v-else-if="risorsa.media && risorsa.media.length > 1" :media="risorsa.media" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration>
                            <LazySezioneContatti :contatti="risorsa" />
                        </LazySkipHydration>

                        <!-- MARCHI / ORARI / TARIFFE / FILE -->
                        <SezioneMarchi :risorsa="risorsa" />
                        <SezioneCardRisorsa :risorsa="risorsa" />
                        <SezioneAccordionRisorsa :risorsa="risorsa" />

                    </div>
                    <aside>

                        <!-- PRODOTTI -->
                        <ListaSideProdotti v-if="risorsa.aderisce_a && risorsa.aderisce_a.length > 0" :prodotti="risorsa.aderisce_a" />

                    </aside>
                </div>
            </div>
        </section>

        <!-- MAPPA -->
        <MappaCoordinate v-if="risorsa.geolocalizzazione && risorsa.geolocalizzazione.coordinates" :latitude="risorsa.geolocalizzazione.coordinates[1]" :longitude="risorsa.geolocalizzazione.coordinates[0]" :altitudine="risorsa.altitudine" />

        <!-- ACCORDION -->
        <SezioneAccordionTarget :risorsa="risorsa" />
        <SezioneAccordionDesign :risorsa="risorsa" />
        <!-- SEDE IN -->
        <ModuloGrid v-if="moduloSedeIn" :modulo="moduloSedeIn" />

        <!-- EVENTI / POTREBBE INTERESSARTI / PROPOSTE TURISTICHE -->
        <ModuloCarousel v-if="moduloEventi" :modulo="moduloEventi" size="small" />
        <ModuloCarousel v-if="moduloProposteTuristiche" :modulo="moduloProposteTuristiche" size="small" />

        <!-- MODULI -->
        <template v-for="modulo in risorsa.moduli">
            <ModuloCarousel v-if="modulo.item && modulo.item.__typename == 'modulo_carousel'" :modulo="modulo.item" />
            <ModuloCarouselSocial v-else-if="modulo.item && modulo.item.__typename == 'modulo_carousel_social'" :modulo="modulo.item" />
        </template>

    </article>
</template>

<script lang="ts" setup>
const { seEventoPassato, sortEventiByOrario } = useOrari()
const { localeProperties } = useI18n()
const { createOpeningHours, getRisorsaType } = useSchema()
const { t } = useI18n()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ risorsa: any, routes: any }>()

const moduloSedeIn = computed(() => {
    if ((props.risorsa.sede_in && props.risorsa.sede_in.length > 0) || (props.risorsa.sede_di && props.risorsa.sede_di.length > 0)) {

        const sedi = [...props.risorsa.sede_in.map((r: any) => r.risorse_id), ...props.risorsa.sede_di.map((r: any) => r.related_risorse_id)]

        const cards = sedi.map((r: any) => {
            return {
                item: {
                    __typename: 'risorse',
                    ...r
                }
            }
        })

        const uniqueCards = [...new Map(cards.map(card => [card.item.id, card])).values()]
        const sortedCards = uniqueCards.sort((a, b) => (a.item.translations[0].titolo > b.item.translations[0].titolo) ? 1 : ((b.item.translations[0].titolo > a.item.translations[0].titolo) ? -1 : 0))

        return {
            contenuto: 'manuale',
            tipologia: 'gridn',
            translations: [
                {
                    titolo: t('sede_di')
                }
            ],
            card: sortedCards
        }
    }
    else return null
})
const moduloEventi = computed(() => {
    if ((props.risorsa.ospita && props.risorsa.ospita.length > 0) || (props.risorsa.organizza && props.risorsa.organizza.length > 0)) {
        const eventi = [...props.risorsa.ospita, ...props.risorsa.organizza]
        if (eventi.length == 0) return null

        const sortedEventi = sortEventiByOrario(eventi, 'eventi_id', 'asc')

        return {
            contenuto: 'manuale',
            translations: [
                {
                    titolo: t('eventi')
                }
            ],
            card: sortedEventi.map((e: any) => {
                return {
                    item: {
                        __typename: 'eventi',
                        ...e
                    }
                }
            })
        }
    } else return null
})
const moduloProposteTuristiche = computed(() => {
    if ((props.risorsa.pacchetti && props.risorsa.pacchetti.length > 0) || (props.risorsa.attivita && props.risorsa.attivita.length > 0)) {
        const pacchetti = props.risorsa.pacchetti && props.risorsa.pacchetti.length > 0 ? props.risorsa.pacchetti : []
        const attivita = props.risorsa.attivita && props.risorsa.attivita.length > 0 ? props.risorsa.attivita : []

        return {
            contenuto: 'manuale',
            translations: [
                {
                    titolo: t('proposte_turistiche')
                }
            ],
            card: [
                ...pacchetti.map((r: any) => {
                    return {
                        item: {
                            __typename: 'pacchetti',
                            ...r.pacchetti_id
                        }
                    }
                }),
                ...attivita.map((a: any) => {
                    return {
                        item: {
                            __typename: 'attivita',
                            ...a.attivita_id
                        }
                    }
                })
            ]
        }
    } else return null
})

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }],
    meta: [
        { name: 'city', content: () => props.risorsa && props.risorsa.comune ? props.risorsa.comune.nome : null },
    ]
});
useSeoMeta({
    title: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? `${props.risorsa.translations[0].og_title} | ${props.risorsa.tipologia?.translations?.[0]?.etichetta} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? `${props.risorsa.translations[0].og_title} | ${props.risorsa.tipologia?.translations?.[0]?.etichetta} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? `${props.risorsa.translations[0].og_title} | ${props.risorsa.tipologia?.translations?.[0]?.etichetta} | Turismo Torino e Provincia` : null,

    description: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description ? props.risorsa.translations[0].og_description : null,
    ogDescription: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description ? props.risorsa.translations[0].og_description : null,
    twitterDescription: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description ? props.risorsa.translations[0].og_description : null,

    ogImage: () => props.risorsa && props.risorsa.cover && props.risorsa.cover.height && props.risorsa.cover.width ? `${directusUrl}assets/${props.risorsa.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.risorsa && props.risorsa.cover && props.risorsa.cover.height && props.risorsa.cover.width ? `${directusUrl}assets/${props.risorsa.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (props.routes && route.name.indexOf('preview') != 0) setI18nParams(props.routes)

const itemListElement = computed(() => {
    const elements = <any[]>[]
    const parents = <{ slug: string, titolo: string }[]>[]
    const urls = <string[]>[]

    let hasMoreParents = true
    let currentParent = props.risorsa.tipologia.pagina

    if (currentParent) {
        while (hasMoreParents) {
            if (currentParent.translations && currentParent.translations.length > 0) {
                const localeParentTranslation = currentParent.translations.find((t: any) => t.languages_code.code == localeProperties.value.code)
                if (localeParentTranslation && localeParentTranslation.slug) {
                    parents.push(localeParentTranslation)
                }
            }

            if (currentParent.parent) currentParent = currentParent.parent
            else hasMoreParents = false
        }
    }

    for (const p of parents.reverse()) {
        urls.push(p.slug)
        elements.push({
            '@type': 'ListItem',
            position: urls.length,
            name: p.titolo,
            item: `https://turismotorino.org/${urls.join('/')}`
        })
    }

    elements.push({
        '@type': 'ListItem',
        position: urls.length + 1,
        name: props.risorsa.translations?.[0]?.titolo,
        item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
    })

    return elements
})

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            'itemListElement': itemListElement.value
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? props.risorsa.translations[0].og_title : 'Turismo Torino e Provincia',
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.risorsa && props.risorsa.cover && props.risorsa.cover.height && props.risorsa.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.risorsa.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.risorsa.cover.height * 1600 / props.risorsa.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.risorsa.cover.title
            } : undefined,
            datePublished: props.risorsa && props.risorsa.date_created ? new Date(props.risorsa.date_created).toJSON() : undefined,
            dateModified: props.risorsa && props.risorsa.date_updated ? new Date(props.risorsa.date_updated).toJSON() : undefined,
            description: props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description ? props.risorsa.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                    ]
                }
            ]
        },
        {
            '@type': getRisorsaType(props.risorsa),
            '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#localbusiness`,
            name: props.risorsa.translations && props.risorsa.translations[0]?.titolo ? props.risorsa.translations[0].titolo : undefined,
            image: props.risorsa.media ? props.risorsa.media.map((m: any) => `${directusUrl}assets/${m.directus_files_id.id}?width=1600&format=jpeg`) : undefined,
            url: props.risorsa.link_esterni && props.risorsa.link_esterni.filter((l: any) => l.tipologia == 'website').length > 0 ? props.risorsa.link_esterni.filter((l: any) => l.tipologia == 'website')[0].link : undefined,
            email: props.risorsa.contatti_email && props.risorsa.contatti_email.length > 0 ? props.risorsa.contatti_email[0].email : undefined,
            address: props.risorsa.indirizzo ? {
                '@type': 'PostalAddress',
                addressCountry: "IT",
                addressLocality: props.risorsa.comune ? props.risorsa.comune.nome : undefined,
                //addressRegion: "",
                postalCode: props.risorsa.cap,
                streetAddress: props.risorsa.indirizzo
            } : undefined,
            geo: props.risorsa.geolocalizzazione && props.risorsa.geolocalizzazione.coordinates ? {
                '@type': 'GeoCoordinates',
                latitude: props.risorsa.geolocalizzazione.coordinates[1],
                longitude: props.risorsa.geolocalizzazione.coordinates[0]
            } : undefined,
            openingHoursSpecification: createOpeningHours(props.risorsa.orari),
            telephone: props.risorsa.contatti_telefono && props.risorsa.contatti_telefono.length > 0 ? props.risorsa.contatti_telefono[0].numero : undefined,
            description: props.risorsa.translations && props.risorsa.translations[0]?.og_description ? props.risorsa.translations[0].og_description : undefined,
            priceRange: props.risorsa.ristorazione_fascia_prezzo ? props.risorsa.ristorazione_fascia_prezzo.numero_normalizzato : undefined,
            servesCuisine: props.risorsa.ristorazione_tipo_cucina && props.risorsa.ristorazione_tipo_cucina.length > 0 ? props.risorsa.ristorazione_tipo_cucina.map((r: any) => r.risorse_tipo_cucina_id.translations[0].etichetta) : undefined,
            sameAs: props.risorsa.link_esterni ? props.risorsa.link_esterni.filter((l: any) => l.tipologia != 'acquista' && l.tipologia != 'prenota').map((l: any) => l.link) : undefined
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .risorsaInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .risorsaInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>